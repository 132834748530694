<template>


<div>

<div class="row">
    <div class="col-4">

                            <v-card dark class="h-100">
							   <v-card-text>
   

      <v-autocomplete
        v-model="company"
        :items="companies"
        :search-input.sync="search"
		@change="companySelected"
        color="white"
        hide-no-data
        hide-selected 
        item-text="company_name"
        item-value="id"
        label="Companies"
        placeholder="Choose Company to Edit"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>

                            </v-card>
						</div>
                        
	<div class="col-lg-8">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-8">
						<h3 class="card-title"> Profile Builder	</h3>

						<p>  This profile is the information that you want to publish for retailers to find you. This is seperate from the business info on your initial company application. </p>
					</div>
					<div class="col-4">

					</div>
				</div>
			</div>
		</div>
	</div>

</div>


        <div class="container">
<b-form class="form" @submit.stop.prevent="submit">

<div class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid" id="kt_subheader">
                            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Page Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Edit your Profile</h5>
                                    <!--end::Page Title-->
                                    <!--begin::Action-->
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>

                                    <span class="text-muted font-weight-bold mr-4"> {{ companyname }}</span>
 <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>



                                    <!--end::Action-->
                                </div>



								<div class="d-flex align-items-center">
									<button type="submit" class="btn btn-success mr-2">Save & Continue Edit</button>
								</div>
                                <!--end::Info-->

                            </div>






              </div>







            <div class="row">
                <div class="col-lg-6">

                    	<!--begin::Card-->
		<div class="card card-custom gutter-b example example-compact">
		
				<div class="card-body">
				
					<div class="form-group row">
						<label  class="col-5 col-form-label">Company Name</label>
						<div class="col-7">
							<b-form-input
								class="form-control"
								id="Companyname"
								name="companyname"
								v-model="$v.companyname.$model"
								:state="validateState('companyname')"
								aria-describedby="input-1-live-feedback"
								placeholder=""
							></b-form-input>

							<b-form-invalid-feedback id="input-1-live-feedback">
								Company name is required.
							</b-form-invalid-feedback>
						</div>
					</div>

		<div class="form-group row">
						<label  class="col-5 col-form-label">City</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_city" v-model="city"/>
						</div>
					</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">State</label>
						<div class="col-7">
							<b-form-select v-model="state" :options="stateOptions"></b-form-select>
						</div>
				</div>
				<div class="form-group row">
						<label for="contact-email-input" class="col-5 col-form-label">Contact Email</label>
						<div class="col-7">
							<b-form-input
								class="form-control"
								id="contact-email"
								name="contact-email"
								v-model="$v.email.$model"
								:state="validateState('email')"
								aria-describedby="input-1-live-feedback"
							></b-form-input>

							<b-form-invalid-feedback id="input-1-live-feedback">
								Email is required and a valid email address.
							</b-form-invalid-feedback>
						</div>
				</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">Phone</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_phone" v-model="phone"/>
						</div>
				</div>
				<div class="form-group row">
						<label  class="col-5 col-form-label">Website</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_website" v-model="website"/>
						</div>
				</div>
				





			
				</div>
				
			
		</div>
		<!--end::Card-->



                </div>

                <div class="col-lg-6">



<div class="card card-custom gutter-b example example-compact">
		
		
				<div class="card-body">

					<div class="form-group row">
						<label  class="col-5 col-form-label">Business Category</label>
						<div class="col-7">
							<b-form-select v-model="category" :options="categoryOptions"></b-form-select>
						</div>
				</div>

<div class="form-group row">
						<label  class="col-5 col-form-label">Average Margin %</label>
						<div class="col-7">
							<input class="form-control" type="number"  id="profile_margin" v-model="averageMargin"/>
						</div>
				</div>
				
				<div class="form-group row">
						<label  class="col-5 col-form-label">MDF %</label>
						<div class="col-7">
							<input class="form-control" type="number"  id="profile_mdf" v-model="mdf"/>
						</div>
				</div>

			<div class="form-group row">
						<label  class="col-5 col-form-label">MAP Policy</label>
						<div class="col-7">
							<ckeditor :editor="editor" v-model="mapPolicy" :config="editorConfig"></ckeditor>
						</div>
				</div>


				<div class="form-group row">
						<label class="col-5 col-form-label" for="gridCheck">Do you have a MAP Policy? </label>
						<div class="col-7">
							<input class="form-check-input" type="checkbox" id="gridCheck" v-model="hasPolicy">
						</div>
				</div>
					<div class="form-group row">
						<label class="col-5 col-form-label">Do you offer Drop Ship? </label>
						<div class="col-7">
							<b-form-select v-model="dropship" :options="dropshipOptions"></b-form-select>
						</div>
				</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">Sales Model</label>
						<div class="col-7">
							<!-- <ckeditor :editor="editor" v-model="salesModel" :config="editorConfig"></ckeditor> -->
							<b-form-select v-model="salesModel" :options="salesModelOptions"></b-form-select>
						</div>
				</div>


				</div>

</div>









                </div>

            </div>




				<div class="row">
						<div class="col-lg-12">

 <div class="form-group row" v-if="coverUrl != ''">
	 <label  class="col-3 col-form-label">Current Header Image</label>
	 <div class="col-9">
	 	<v-img :src="coverUrl"  height="200px" alt="..."> </v-img>
	 </div>
 </div>

							<div class="form-group row">

	<label  class="col-3 col-form-label">Header Image with Preview</label>

<div class="col-9">

<b-form-file
     v-model="cover"
     :state="Boolean(cover)"
     :placeholder="coverImgName == '' ? 'Choose an image or drop it here...': coverImgName"
     drop-placeholder="Drop file here..."
	   	accept="image/*" 

   ></b-form-file>


</div>
 </div>

 <div class="form-group row" v-if="searchImgUrl != ''">
	 <label  class="col-3 col-form-label">Current Search Header Image</label>
	 <div class="col-9">
	 	<v-img :src="searchImgUrl"  height="120px" width="200px" alt="..."> </v-img>
	 </div>
 </div>

							<div class="form-group row">

	<label  class="col-3 col-form-label">Search Header Image</label>

<div class="col-9">

<b-form-file
     v-model="searchImg"
     :state="Boolean(searchImg)"
     :placeholder="searchImgName == '' ? 'Choose an image or drop it here...': searchImgName"
     drop-placeholder="Drop file here..."
	   	accept="image/*" 

   ></b-form-file>


</div>
 </div>
<div class="form-group row">
						<label  class="col-5 col-form-label">Promo Search Description</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_teaser" v-model="teaserText"/>
						</div>
				</div>
						
<div class="form-group row">

	<label  class="col-3 col-form-label">Teaser Description WYSIWYG</label>

<div class="col-9">
	<ckeditor :editor="editor" v-model="teaserData" :config="editorConfig"></ckeditor>

   <pre class="mt-3 mb-0"></pre>
</div>
 </div>

<div class="form-group row">

	<label  class="col-3 col-form-label">Long Description WYSIWYG</label>

<div class="col-9">
	<ckeditor :editor="editor" v-model="descData" :config="editorConfig"></ckeditor>

   <pre class="mt-3 mb-0"></pre>
</div>
 </div>

 <div class="form-group row" v-if="descImgUrl != ''">
	 <label  class="col-3 col-form-label">Current Description Image</label>
	 <div class="col-9">
	 	<v-img :src="descImgUrl"  height="200px" width="200px" alt="..."> </v-img>
	 </div>
 </div>

<div class="form-group row">

	<label  class="col-3 col-form-label">Description Image</label>

<div class="col-9">

<b-form-file
     v-model="descImg"
     :state="Boolean(descImg)"
     :placeholder="descImgName == '' ? 'Choose an image or drop it here...': descImgName"
     drop-placeholder="Drop file here..."
  	accept="image/*" 

   ></b-form-file>


</div>
 </div>

<div class="form-group row">

	<label  class="col-3 col-form-label">Dealer Requirements WYSIWYG</label>

<div class="col-9">
	<ckeditor :editor="editor" v-model="dealerData" :config="editorConfig"></ckeditor>

   <pre class="mt-3 mb-0"></pre>
</div>
 </div>





						</div>




				</div>









</b-form>
        </div>









</div>


</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import gql from "graphql-tag";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
	mixins: [validationMixin],
	data() {
		return {
			company: null,
			search: "",
			companies: [],
			coverUrl: '',
			searchImgUrl: '',
			descImgUrl: '',
			profileId: '',
			cover: null,
			searchImg: null,
			descImg: null,
			coverImgName: '',
			searchImgName: '',
			descImgName: '',
			companyname: '',
			city: '',
			state: '',
			email: '',
			phone: '',
			website: '',
			category: '',
			categoryOptions: [
				{
					value: "homegoods",
					text: 'Home Goods',
				},
				{
					value: "pets",
					text: 'Pet Products',
				},
				{
					value: "outdoors",
					text: 'Outdoors & Water',
				},
				{
					value: "automotive",
					text: 'Automotive',
				},
				{
					value: "fishing",
					text: 'Fishing',
				},
				{
					value: "skiboard",
					text: 'Ski & Snowboard',
				},
				{
					value: "sporting_goods",
					text: 'Sporting Goods',
				},
				{
					value: "mens_fashion",
					text: 'Mens Fashion',
				},
				{
					value: "womens_fashion",
					text: 'Womens Fashion',
				},
				{
					value: "kids_fashion",
					text: 'Youth Fashion',
				},
				{
					value: "fashion_general",
					text: 'General Fashion',
				},
				
			],
			averageMargin: '',
			mdf: '',
			teaserText: '',
			mapPolicy: '',
			hasPolicy: false,
			Published: false,
			dropship: 'yes',
			dropshipOptions: [
				{
					value: "yes",
					text: 'Yes',
				},
				{
					value: "no",
					text: 'No',
				},
			],
			salesModel: '',
			salesModelOptions: [
				{
					value: "Partners Only",
					text: 'Partners Only',
				},
				{
					value: "Partners & Direct",
					text: 'Partners & Direct',
				},
			],
			editor: ClassicEditor,
			teaserData: '',
			descData: '',
			dealerData: '',
			editorConfig: {
				toolbar: {
					items: [
						'heading',
						'|',
						'bold',
						'italic',
						'bulletedList',
						'numberedList',
						'|',
						'insertTable',
						'|',
						'undo',
						'redo'
					]
				},
			},
			stateOptions: [
				{
					value: "AL",
					text: "AL",
				},
				{
					value: "AK",
					text: "AK",
				},
				{
					value: "AZ",
					text: "AZ",
				},
				{
					value: "AR",
					text: "AR",
				},
				{
					value: "CA",
					text: "CA",
				},
				{
					value: "CO",
					text: "CO",
				},
				{
					value: "CT",
					text: "CT",
				},
				{
					value: "DE",
					text: "DE",
				},
				{
					value: "FL",
					text: "FL",
				},
				{
					value: "GA",
					text: "GA",
				},
				{
					value: "HI",
					text: "HI",
				},
				{
					value: "ID",
					text: "ID",
				},
				{
					value: "IL",
					text: "IL",
				},
				{
					value: "IN",
					text: "IN",
				},
				{
					value: "IA",
					text: "IA",
				},
				{
					value: "KS",
					text: "KS",
				},
				{
					value: "KY",
					text: "KY",
				},
				{
					value: "LA",
					text: "LA",
				},
				{
					value: "ME",
					text: "ME",
				},
				{
					value: "MD",
					text: "MD",
				},
				{
					value: "MA",
					text: "MA",
				},
				{
					value: "MI",
					text: "MI",
				},
				{
					value: "MN",
					text: "MN",
				},
				{
					value: "MS",
					text: "MS",
				},
				{
					value: "MO",
					text: "MO",
				},
				{
					value: "MT",
					text: "MT",
				},
				{
					value: "NE",
					text: "NE",
				},
				{
					value: "NV",
					text: "NV",
				},
				{
					value: "NH",
					text: "NH",
				},
				{
					value: "NJ",
					text: "NJ",
				},
				{
					value: "NM",
					text: "NM",
				},
				{
					value: "NY",
					text: "NY",
				},
				{
					value: "NC",
					text: "NC",
				},
				{
					value: "ND",
					text: "ND",
				},
				{
					value: "OH",
					text: "OH",
				},
				{
					value: "OK",
					text: "OK",
				},
				{
					value: "OR",
					text: "OR",
				},
				{
					value: "OK",
					text: "OK",
				},
				{
					value: "OR",
					text: "OR",
				},
				{
					value: "PA",
					text: "PA",
				},
				{
					value: "RI",
					text: "RI",
				},
				{
					value: "SC",
					text: "SC",
				},
				{
					value: "SD",
					text: "SD",
				},
				{
					value: "TN",
					text: "TN",
				},
				{
					value: "TX",
					text: "TX",
				},
				{
					value: "UT",
					text: "UT",
				},
				{
					value: "VT",
					text: "VT",
				},
				{
					value: "VA",
					text: "VA",
				},
				{
					value: "WA",
					text: "WA",
				},
				{
					value: "WV",
					text: "WV",
				},
				{
					value: "WI",
					text: "WI",
				},
				{
					value: "WY",
					text: "WY",
				},
			]
		}
	},
	apollo: {
    companies: {
      query: gql`
        query Companies($search: String) {
          companies(
            where: {
				type: "supplier",
              	company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.search == null ? '' : this.search
        }
      },
    }
  },
	validations: {
		email: {
			required,
			email
		},
		companyname: {
			required
		},
	},
	methods: {
		...mapActions([
			'getCompanyInfo',
			'updateProfileInfo'
		]),
		validateState(name) {
			const { $dirty, $error } = this.$v[name];
			return $dirty ? !$error : null;
		},
		companySelected() {
			this.getCompanyInfo({
				id: this.company.id
			})
			.then((res) => {
				var baseurl = process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337';
				if(res.data.profile.profile_cover != null && res.data.profile.profile_cover != undefined) {
					this.coverUrl = baseurl + res.data.profile.profile_cover.url;
					this.coverImgName = res.data.profile.profile_cover.name + '.png';
				}
				else {
					this.coverUrl = '';
					this.coverImgName = '';
				}
				if(res.data.profile.profile_search_img != null && res.data.profile.profile_search_img != undefined) {
					this.searchImgUrl = baseurl + res.data.profile.profile_search_img.url;
					this.searchImgName = res.data.profile.profile_search_img.name + '.png';
				}
				else {
					this.searchImgUrl = '';
					this.searchImgName = '';
				}
				if(res.data.profile.profile_desc_img != null && res.data.profile.profile_desc_img != undefined) {
					this.descImgUrl = baseurl + res.data.profile.profile_desc_img.url;
					this.descImgName = res.data.profile.profile_desc_img.name + '.png';
				}
				else {
					this.descImgUrl = '';
					this.descImgName = '';
				}
				this.profileId = res.data.profile.id;
				this.Published = res.data.profile.Published;

				this.companyname = res.data.profile.profile_company_name;
				this.city = res.data.profile.profile_city;
				this.state = res.data.profile.profile_state;
				this.email = res.data.profile.profile_contact_email;
				this.category = res.data.profile.profile_category; 
				this.phone = res.data.profile.profile_phone;
				this.website = res.data.profile.profile_website;
				this.dropship = res.data.profile.profile_dropship;
				this.averageMargin = res.data.profile.profile_average_margin;
				this.teaserText = res.data.profile.promo_text;
				this.mdf = res.data.profile.profile_mdf_percent;
				if (res.data.profile.profile_map_text != null && res.data.profile.profile_map_text != undefined) {
					this.mapPolicy = res.data.profile.profile_map_text;
				}
				this.hasPolicy = res.data.profile.profile_map_policy;
				if (res.data.profile.profile_sales_model != null && res.data.profile.profile_sales_model != undefined) {
					this.salesModel = res.data.profile.profile_sales_model;
				}
				if (res.data.profile.profile_teaser_description != null && res.data.profile.profile_teaser_description != undefined) {
					this.teaserData = res.data.profile.profile_teaser_description;
				}
				if (res.data.profile.profile_long_description != null && res.data.profile.profile_long_description != undefined) {
					this.descData = res.data.profile.profile_long_description;
				}
				if (res.data.profile.profile_dealer_requirements != null && res.data.profile.profile_dealer_requirements != undefined) {
					this.dealerData = res.data.profile.profile_dealer_requirements;
				}
			})
		},
		submit(e) {
			e.preventDefault();
			if (this.profileId != '') {
				this.updateProfileInfo({
					id: this.profileId,
					profile_company_name: this.companyname,
					profile_city: this.city,
					Published: this.Published,
					profile_state: this.state,
					profile_contact_email: this.email,
					profile_phone: this.phone,
					profile_website: this.website,
					profile_average_margin: this.averageMargin,
					profile_mdf_percent: this.mdf,
					profile_map_text: this.mapPolicy,
					profile_map_policy: this.hasPolicy,
					profile_sales_model: this.salesModel,
					profile_teaser_description: this.teaserData,
					profile_long_description: this.descData,
					profile_dealer_requirements: this.dealerData,
					profile_cover: this.cover,
					profile_search_img: this.searchImg,
					profile_desc_img: this.descImg,
					profile_category: this.category,
					promo_text: this.teaserText,
					profile_dropship: this.dropship,
					profile_search_content: this.companyname + '_' + this.descData + '_' + this.teaserData
				})
				.then((res) => {
					Swal.fire({
						title: "",
						text: "The profile has been successfully updated!",
						icon: "success",
						confirmButtonClass: "btn btn-secondary"
					});

					var baseurl = process.env.VUE_APP_STRAPI_API_URL || 'http://localhost:1337';
					if(res.data.profile_cover != null && res.data.profile_cover != undefined) {
						this.coverUrl = baseurl + res.data.profile_cover.url;
					}
					if(res.data.profile_search_img != null && res.data.profile_search_img != undefined) {
						this.searchImgUrl = baseurl + res.data.profile_search_img.url;
					}
					if(res.data.profile_desc_img != null && res.data.profile_desc_img != undefined) {
						this.descImgUrl = baseurl + res.data.profile_desc_img.url;
					}
				})
				.catch(() => {
					this.$bvToast.toast('An Error Occured!', {
						title: 'Error',
						variant: 'danger',
						toaster: 'b-toaster-bottom-right',
						solid: true,
						appendToast: true
					});
				});
			}
			else {
				this.$bvToast.toast('No profile in the company.', {
					title: 'Error',
					variant: 'danger',
					toaster: 'b-toaster-bottom-right',
					solid: true,
					appendToast: true
				});
			}
		}
	},
	computed: {
		...mapGetters([
			'currentUser'
		]),
	}
}
</script>